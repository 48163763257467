import plus from "../Images/PLUS.png";
import bargraph from "../Images/graph2.png";
import linegraph from "../Images/GRAPH1.png";
import dots from "../Images/SITE_DIVIDER_DOTS.png";
import "../App.css";
import { FullLeaderboard } from "../DIFF/FullLeaderboard";
import { useEffect, useState } from "react";

export function Leaderboards(props: any) {
  return (
    <>
      <div className="new_block grey">
        <div className="padding_box_writing new_block">
          <div className="upperBox">
            <img src={linegraph} className="lineGraph" alt="logo" />
            <img src={bargraph} className="barGraph" alt="logo" />
            <img
              src={plus}
              style={{ height: "30px", position: "absolute", left: "0" }}
              alt="logo"
            />
            <div
              className="boxHeading"
              style={{
                minHeight: "10rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <p className="orange_text headingText">LEADERBOARD</p>
            </div>
          </div>
        </div>
        <div className="leaderContainer">
          <div className="leftLeader">
            <FullLeaderboard
              text={"TOTAL A-EYE DESTROYED"}
              leadersData={props.leadersData}
              personalStat="A-EYE_Drones_Destroyed"
              individualData={props.individualData}
            />
          </div>
          <div className="rightLeader">
            <FullLeaderboard
              text={"HIGH SCORE"}
              leadersData={props.leadersData}
              personalStat="Elixir_HS"
              individualData={props.individualData}
            />
          </div>
        </div>
        <img src={dots} className="bottom" alt="logo" />
      </div>
    </>
  );
}
