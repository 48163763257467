import { SquareShow } from "../DIFF/SquareShow";
import plus from "../Images/PLUS.png";
import bargraph from "../Images/graph2.png";
import linegraph from "../Images/GRAPH1.png";

import dots from "../Images/SITE_DIVIDER_DOTS.png";
import "../App.css";
import { useEffect, useState } from "react";
import { DisplayRewards } from "../DIFF/DisplayRewards";
import { Milestone } from "../DIFF/Milestone";

export function Global(props: any) {
  const [global, setGlobal] = useState(0);
  const [level, setLevel] = useState<any>(0);
  const [rewards, setRewards] = useState<any>([]);

  function numberWithCommas(x: Number) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  useEffect(() => {
    let results;

    if (props.globalValue) {
      results = props.globalValue.value;
    } else {
      results = 0;
    }
    setGlobal(results);
    calcRewards(results);
  }, [props.globalValue, props.globalLimits]);

  function calcRewards(numberKills: any) {
    let levelRef = 0;
    const givenRewards: string[] = [];
    levelRef = Math.floor((10 * numberKills) / props.globalLimits[0]);
    if (numberKills >= props.globalLimits[0]) {
      givenRewards.push("1_community_decode1.png");
      levelRef =
        Math.floor(
          (10 * (numberKills - props.globalLimits[0])) /
            (props.globalLimits[1] - props.globalLimits[0])
        ) + 10;
      if (numberKills >= props.globalLimits[1]) {
        givenRewards.push("2_community_decode2.png");
        givenRewards.push("2_community_keyart.png");
        levelRef =
          Math.floor(
            (10 * (numberKills - props.globalLimits[1])) /
              (props.globalLimits[2] - props.globalLimits[1])
          ) + 20;
        if (numberKills >= props.globalLimits[2]) {
          givenRewards.push("3_community_decode3.png");
          givenRewards.push("3_community_raffle.png");
          levelRef =
            Math.floor(
              (10 * (numberKills - props.globalLimits[2])) /
                (props.globalLimits[3] - props.globalLimits[2])
            ) + 30;
          if (numberKills >= props.globalLimits[3]) {
            givenRewards.push("4_community_decode4.png");
            givenRewards.push("4_community_comicstandard.png");
            levelRef =
              Math.floor(
                (10 * (numberKills - props.globalLimits[3])) /
                  (props.globalLimits[4] - props.globalLimits[3])
              ) + 40;
            if (numberKills >= props.globalLimits[4]) {
              givenRewards.push("5_community_decode5.png");
              givenRewards.push("5_community_elord.png");
              levelRef = 50;
            }
          }
        }
      }
    } else {
      givenRewards.push("NO_REWARDS_UNLOCKED.png");
    }
    setLevel(levelRef);
    setRewards(givenRewards);
  }

  return (
    <>
      <div className="black new_block">
        <div className="padding_box_writing new_block">
          <div className="upperBox">
            <img src={linegraph} className="lineGraph" alt="logo" />
            <img src={bargraph} className="barGraph" alt="logo" />
            <img
              src={plus}
              style={{ height: "30px", position: "absolute", left: "0" }}
              alt="logo"
            />
            <div className="boxHeading">
              <p className="headingText">
                <span className="semibold">GLOBAL </span>
                <span className="orange_text">A-EYE</span>{" "}
                <span className="semibold">DESTROYED</span>
                <br />
                <span
                  className="orange_text"
                  style={{
                    fontSize: "5.5rem",
                  }}
                >
                  {numberWithCommas(global)}
                </span>
              </p>
            </div>
          </div>
          {props.lastModified && (
            <div style={{ padding: "0rem" }}>
              <p className="orange_text lastModified">
                Last Modified on {props.lastModified}
              </p>
              <p className="orange_text lastModified">
                Updates every 20 minutes
              </p>
            </div>
          )}
        </div>

        <img src={dots} className="bottom" alt="logo" />
      </div>
      <div className="grey new_block">
        <div className="padding_box_writing new_block">
          <div className="upperBox">
            <img src={linegraph} className="lineGraph" alt="logo" />
            <img src={bargraph} className="barGraph" alt="logo" />
            <img
              src={plus}
              style={{ height: "30px", position: "absolute", left: "0" }}
              alt="logo"
            />
            <div className="boxHeading">
              <p className="orange_text headingText">
                <span className="semibold">GLOBAL </span>TRACKER
              </p>
              <SquareShow />
            </div>
          </div>

          <Milestone type={"community"} level={level} />

          <div className="upperBox">
            <img
              src={plus}
              style={{ height: "30px", position: "absolute", left: "0" }}
              alt="logo"
            />
            <div className="boxHeading">
              <p className="orange_text headingText">
                <span className="semibold">GLOBAL </span>REWARDS UNLOCKED
              </p>
              <DisplayRewards rewards={rewards} />
            </div>
          </div>
        </div>
        <img src={dots} className="bottom" alt="logo" />
      </div>
    </>
  );
}
