import plus from "../Images/PLUS.png";
import "../App.css";
import { useEffect, useState } from "react";

export function FullLeaderboard(props: any) {
  const [entities, setEntities] = useState<any>([]);

  function numberWithCommas(x: Number) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  useEffect(() => {
    const entities_inter = [];
    if (props.leadersData) {
      const isLeaderboardName = (element: any) =>
        element.name === props.personalStat;
      const index_leader =
        props.leadersData.leaderboards.findIndex(isLeaderboardName);
      if (index_leader > -1) {
        if (props.individualData) {
          const isGameState = (element: any) =>
            element.StatisticName === props.personalStat;
          const index_stat =
            props.individualData.PlayerStatistics.findIndex(isGameState);
          let scores = 0;
          if (index_stat >= 0) {
            scores = Number(
              props.individualData.PlayerStatistics[index_stat].Value
            );
          }

          const isPlayer = (element: any) =>
            element.PlayFabId === props.individualData.AccountInfo.PlayFabId;
          const index_rank =
            props.leadersData.leaderboards[index_leader].leaderboard.findIndex(
              isPlayer
            );
          if (
            index_rank > -1 &&
            props.leadersData.leaderboards[index_leader].leaderboard[
              index_rank
            ] > 0
          ) {
            entities_inter.push(
              <div className="containerNames" key={1000}>
                <div className="small-part">
                  <div className="numberBox">
                    <p className="whiteGlow textBox">
                      {props.leadersData.leaderboards[index_leader].leaderboard[
                        index_rank
                      ].Position + 1}
                    </p>
                  </div>
                </div>
                <div className="large-part">
                  <div className="textBoxLeader">
                    <p
                      className="textBox"
                      style={{
                        textAlign: "left",
                        paddingLeft: "1rem",
                      }}
                    >
                      {props.individualData.AccountInfo.TitleInfo.DisplayName}
                    </p>
                  </div>
                </div>
                <div className="large-part">
                  <div className="textBoxLeader">
                    <p className="textBox">{numberWithCommas(scores)}</p>
                  </div>
                </div>
              </div>
            );
          }
        }
        const length = Math.min(
          10,
          props.leadersData.leaderboards[index_leader].leaderboard.length
        );
        for (let i = 0; i < length; i++) {
          const isPlayerRank = (element: any) => element.Position === i;
          const index_player =
            props.leadersData.leaderboards[index_leader].leaderboard.findIndex(
              isPlayerRank
            );

          const name =
            props.leadersData.leaderboards[index_leader].leaderboard[
              index_player
            ].DisplayName;
          const score =
            props.leadersData.leaderboards[index_leader].leaderboard[
              index_player
            ].StatValue;
          entities_inter.push(
            <div className="containerNames" key={i}>
              <div className="small-part">
                <div className="numberBox">
                  <p className="whiteGlow textBox">{i + 1}</p>
                </div>
              </div>
              <div className="large-part">
                <div className="textBoxLeader">
                  <p
                    className="textBox"
                    style={{
                      textAlign: "left",
                      paddingLeft: "1rem",
                    }}
                  >
                    {name}
                  </p>
                </div>
              </div>
              <div className="large-part">
                <div className="textBoxLeader">
                  <p className="textBox">{numberWithCommas(score)}</p>
                </div>
              </div>
            </div>
          );
        }

        setEntities(entities_inter);
      }
    }
  }, [
    props.name,
    props.score,
    props.individualData,
    props.personalStat,
    props.leadersData,
  ]);

  return (
    <>
      <div className="new_block" style={{ width: "90%" }}>
        <div className="upperBox" style={{ margin: "0", width: "100%" }}>
          <img
            src={plus}
            style={{ height: "30px", position: "absolute", left: "0" }}
            alt="logo"
          />
          <div
            className="boxHeading"
            style={{
              margin: "0 auto",
              display: "flex",
              padding: "0rem 2rem",
              alignItems: "center",
              minHeight: "4rem",
            }}
          >
            <p
              className="highScore"
              style={{
                textAlign: "center",
                fontSize: "2rem",
                lineHeight: "1.5",
              }}
            >
              {props.text}
            </p>
          </div>
        </div>
        <div style={{ margin: "0", width: "100%", paddingTop: "3rem" }}>
          {entities}
        </div>
      </div>
    </>
  );
}
