import Squares from "../Images/SITE_GREY_SQUARE.png";

export function SquareShow() {
  const squares = [];
  const numrows = 10;
  for (let i = 0; i < numrows; i++) {
    // note: we are adding a key prop here to allow react to uniquely identify each
    // element in this array. see: https://reactjs.org/docs/lists-and-keys.html
    squares.push(
      <img
        style={{ height: "90px", width: "55px" }}
        src={Squares}
        key={i}
        alt=""
      />
    );
  }
  return <div className="squares"> {squares}</div>;
}
