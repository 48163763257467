import React, { useRef, useEffect, useState } from "react";

function PopupWindow(props: any) {
  const [heightVal, setHeightVal] = useState("85vh");
  const [classes, setClasses] = useState("popup");
  useEffect(() => {
    if (props.link === "/images/howtofindplayerID.png") {
      setHeightVal("60vh");
      setClasses("popup colourDiff");
    } else {
      setHeightVal("85vh");
      setClasses("popup");
    }
  }, [props.link]);

  return (
    <div className="popup-container">
      <div className={classes}>
        <button className="close-button" onClick={props.onClose}>
          X
        </button>
        <img src={props.link} alt="" style={{ height: heightVal }} />
      </div>
    </div>
  );
}
export default PopupWindow;
