import logoBD from "../Images/Bd_logo.svg";
import logoOAEYE from "../Images/LOGO_GIF.gif";
import "../App.css";

export function Header() {
  return (
    <>
      <div className="padding_box">
        <img src={logoBD} className="App-logo" alt="logo" />
      </div>
      <div className="padding_box">
        <img
          style={{ maxHeight: "150px" }}
          src={logoOAEYE}
          className="App-logo operation"
          alt="logo"
        />
      </div>
      <div style={{ paddingTop: "2rem" }}></div>
    </>
  );
}
