import { useEffect, useState } from "react";
import { ShowOneReward } from "./ShowOneReward";

export function DisplayRewards(props: any) {
  const [rewards, setRewards] = useState<any>([]);

  useEffect(() => {
    const rewardsImages = []; //
    for (let i = 0; i < props.rewards.length; i++) {
      const firstImage = "/milestone/biggericons/" + props.rewards[i];
      const secondImage = "/milestone/hovers/" + props.rewards[i];
      rewardsImages.push(
        <ShowOneReward
          firstImage={firstImage}
          secondImage={secondImage}
          key={i}
        />
      );
    }
    setRewards(rewardsImages);
  }, [props.rewards]);

  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        justifyContent: "flex-start",
        flexWrap: "wrap",
        padding: "1rem",
      }}
    >
      {rewards}
    </div>
  );
}
