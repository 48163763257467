import { useState, useEffect } from "react";
import "./App.css";
import { Global } from "./Components/Global";
import { Header } from "./Components/Header";
import { Individual } from "./Components/Individual";
import { Leaderboards } from "./Components/Leaderboards";
import { PilotTime } from "./Components/PilotTime";
import PopupWindow from "./Components/PopupWindow";

function App() {
  const [showPopup, setShowPopup] = useState(false);
  const [playerJSONData, setPlayerJSONData] = useState<any>();
  const [leaderboardJSONData, setLeaderboardJSONData] = useState<any>();
  const [globalValue, setGlobalValue] = useState<any>();
  const [individualData, setIndividualData] = useState<any>(null);
  const [link, setLink] = useState<any>("/images/see_more.png");
  const [lastModified, setLastModified] = useState<any>("");

  const [globalLimits, setGlobalLimits] = useState([
    10000, 20000, 40000, 60000, 100000,
  ]);
  const [individalLimit, setIndividalLimit] = useState([
    100, 1000, 2000, 3500, 6000,
  ]);

  useEffect(() => {
    fetch("/JSON/PlayerData.json")
      .then((response) => response.json())
      .then((data) => {
        setPlayerJSONData(data);
      });
  }, []);

  useEffect(() => {
    fetch("/JSON/Leaderboard.json")
      .then((response) => response.json())
      .then((data) => {
        setLeaderboardJSONData(data);
      });
  }, []);

  function convertUTCDateToLocalDate(date: Date) {
    var newDate = new Date(
      date.getTime() + date.getTimezoneOffset() * 60 * 1000
    );

    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();

    newDate.setHours(hours - offset);

    return newDate;
  }

  useEffect(() => {
    fetch("/JSON/Global.json")
      .then((response) => {
        const lastModified_ = response.headers.get("last-modified");

        if (lastModified_) {
          const date = new Date(lastModified_);
          // console.log(date.toString());
          let dateStr = date.toString();
          const GMTindex = dateStr.indexOf("GMT");
          if (GMTindex) {
            dateStr = dateStr.slice(0, GMTindex);
          }
          setLastModified(dateStr);
        }

        return response.json();
      })
      .then((data) => {
        setGlobalValue(data);
      });
  }, []);

  function handleClick() {
    setShowPopup(true);
    document.body.classList.add("no-scroll");
  }

  function handleClose() {
    setShowPopup(false);
    document.body.classList.remove("no-scroll");
  }

  return (
    <>
      {" "}
      <div className="App">
        {" "}
        <div className={showPopup ? "blurred-background" : "App-header"}>
          <Header />
          <PilotTime onClick={handleClick} setLink={setLink} />
          <Global
            globalLimits={globalLimits}
            globalValue={globalValue}
            lastModified={lastModified}
          />
          <Individual
            limits={individalLimit}
            individalData={individualData}
            playersData={playerJSONData}
            leadersData={leaderboardJSONData}
            setIndividualData={setIndividualData}
            onClick={handleClick}
            setLink={setLink}
          />
          <Leaderboards
            individualData={individualData}
            leadersData={leaderboardJSONData}
          />
          {lastModified && (
            <div style={{ padding: "2rem 0" }}>
              <p className="orange_text lastModified">
                Last Modified on {lastModified}
              </p>
              <p className="orange_text lastModified">
                Updates every 20 minutes
              </p>
            </div>
          )}
        </div>
        {showPopup && <PopupWindow onClose={handleClose} link={link} />}
      </div>
    </>
  );
}

export default App;
