import { useEffect, useState } from "react";
import "../App.css";

export function Milestone(props: any) {
  const [imageMilestone, setImageMilestone] = useState("");
  const [imageNumber, setImageNumber] = useState("");

  useEffect(() => {
    if ((props.level || props.level === 0) && props.type) {
      const imageNumberRef = Math.min(Math.ceil((props.level + 1) / 10), 5);
      setImageNumber(
        "/milestone/numbers/" +
          props.type.toUpperCase() +
          "_NUMBERS_" +
          imageNumberRef +
          ".png"
      );
      const imageMilestoneRef = props.level.toString().padStart(2, "0");
      setImageMilestone(
        "/milestone/" +
          props.type +
          "/" +
          props.type +
          "_tracker-" +
          imageMilestoneRef +
          ".png"
      );
    }
  }, [props.level, props.type]);

  return (
    <>
      <div style={{ padding: "2rem" }}>
        <img
          style={{ width: "100%", maxWidth: "1200px" }}
          src={imageNumber}
          className=""
          alt="milstone"
        />
        <img
          style={{ width: "100%", maxWidth: "1200px" }}
          src={imageMilestone}
          className=""
          alt="milstone"
        />
      </div>
    </>
  );
}
